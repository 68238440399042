import React from 'react';
import './Failure.scss';
import { useTranslation } from 'react-i18next';

export const Failure = React.memo(
  ({ failureImage }: { failureImage: string }): JSX.Element => {
    const { t } = useTranslation();

    return (
      <div className="failure">
        <img
          width="100"
          alt="Error"
          src={failureImage}
          aria-label="Error icon"
        />
        <h1>{t('errorTitle')}</h1>
        <p>{t('error')}</p>
      </div>
    );
  },
);
