import { UIConfig, FontVariations } from '../models';

export const initFonts = (uiConfig: UIConfig): void => {
  const fonts = Object.keys(uiConfig.fontAssets).map((fontName) => {
    const fontDefinition = uiConfig.fontAssets[fontName];
    return {
      name: fontName,
      variations: getFontFaceVariations(fontDefinition),
    };
  });
  const fontFaces = fonts.map((font) =>
    getCSSFontFace(font.name, font.variations),
  );
  fontFaces.forEach((fontFaceVariations) =>
    appendFontFaceVariations(fontFaceVariations),
  );
};

const getFontFaceVariations = (
  fontDefinition: FontVariations,
): FontVariations => {
  const variations: FontVariations = {};

  Object.keys(fontDefinition).forEach((style) => {
    Object.keys(fontDefinition[style]).forEach((weight) => {
      if (!variations[style]) {
        variations[style] = {};
      }
      if (!variations[style][weight]) {
        variations[style][weight] = {
          ...fontDefinition[style][weight],
        };
      } else {
        variations[style][weight] = { ...fontDefinition[style][weight] };
      }
    });
  });

  return variations;
};

const appendFontFaceVariations = (fontFaceVariations: string[]): void => {
  fontFaceVariations.forEach((fontFace) => {
    const styleElement = document.createElement('style');
    const node = document.createTextNode(fontFace);
    styleElement.appendChild(node);
    document.body.appendChild(styleElement);
  });
};

const getCSSFontFace = (name: string, variations: FontVariations): string[] => {
  return Object.keys(variations)
    .map((style) => {
      return Object.keys(variations[style]).map((weight) => {
        return `@font-face {
      font-family:"${name}";
      font-style:${style};
      font-weight:${weight};
      src: url("${variations[style][weight].data}") format("${variations[style][weight].format}")`;
      });
    })
    .reduce((acc, cur) => acc.concat(cur), []);
};
