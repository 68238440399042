import { Config, UIConfig } from '../models';
import { initFonts, initThemeStyles } from '../utils';
import { apiService } from './api.service';
import { translationsService } from './translations.service';
import { ConfigScheme } from '../json-schemes';

export class ConfigurationService {
  config!: Config;

  getConfig(): Config {
    return this.config;
  }

  async loadConfig(): Promise<void> {
    const config: Config = await apiService.getCustomerConfig();

    this.config = config;
    if (!this.isConfigValid(config)) {
      throw Error('Config is invalid');
    }

    await translationsService.initTranslations(config.languages);
    initThemeStyles(this.config.uiConfig);
    initFonts(this.config.uiConfig);
    this.initFavicon(this.config.uiConfig);
  }

  private initFavicon(config: UIConfig): void {
    const link: HTMLLinkElement = document.createElement('link');
    link.setAttribute('href', config.images.favicon);
    link.setAttribute('rel', 'icon');
    document.head.appendChild(link);
  }

  private isConfigValid(config: Config): boolean {
    const result = ConfigScheme.safeParse(config);

    if (!result.success) {
      console.error('Config validation errors:', result.error?.issues);
      const issues: Record<string, { Error: string }> = {};
      result.error?.issues.forEach((err) => {
        issues[err.path.join('.')] = { Error: err.message };
      });
      console.table(issues, ['Error']);
    }

    return result.success;
  }
}

export const configurationService = new ConfigurationService();
