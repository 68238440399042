import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '../../hooks';
import './Dropdown.scss';

interface DropdownItem {
  id: string;
  name: string;
}

interface DropdownProps {
  id: string;
  title?: string;
  placeholder?: string;
  data: DropdownItem[];
  selectedId?: string;
  onSelect?: (id: string) => void;
  className?: string;
  icon?: JSX.Element;
}

export const Dropdown = React.memo(
  ({
    id,
    title = 'Select',
    data,
    selectedId,
    onSelect,
    icon,
    className,
    placeholder,
  }: DropdownProps): JSX.Element => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>(
      selectedId ? data?.find((item) => item.id === selectedId) : undefined,
    );
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleChange = useCallback(
      (item: DropdownItem) => (): void => {
        setSelectedItem(item);
        onSelect && onSelect(item.id);
        setIsOpen(false);
      },
      [setSelectedItem, onSelect, setIsOpen],
    );

    useEffect(() => {
      if (selectedId && data) {
        const newSelectedItem = data.find((item) => item.id === selectedId);
        newSelectedItem && setSelectedItem(newSelectedItem);
      } else {
        setSelectedItem(undefined);
      }
    }, [selectedId, data]);

    useOutsideClick({
      ref: dropdownRef,
      handler: () => setIsOpen(false),
    });

    const triggerIsOpened = (): void => setIsOpen((opened) => !opened);

    const collapse = useCallback(() => {
      setIsOpen(false);
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }, [setIsOpen, buttonRef]);

    const handleOptionKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLLIElement>, option: DropdownItem) => {
        switch (e.key) {
          case 'ArrowDown':
            e.preventDefault();
            // if there's a next item, focus on it
            if (e.currentTarget.nextSibling) {
              (e.currentTarget.nextSibling as HTMLLIElement).focus();
              break;
            }

            // if there's no next list item (last item), focus on the first item
            if (!e.currentTarget.nextSibling) {
              (
                e.currentTarget.parentNode?.childNodes[0] as HTMLLIElement
              ).focus();
              break;
            }
            break;

          case 'ArrowUp':
            e.preventDefault();
            // if there's a previous item, focus on it
            if (e.currentTarget.previousSibling) {
              (e.currentTarget.previousSibling as HTMLLIElement).focus();
              break;
            }
            // if there's no previous list item (first item), focus on the last item
            if (
              !e.currentTarget.previousSibling &&
              e.currentTarget.parentNode
            ) {
              const indexOfLastElement =
                e.currentTarget?.parentNode?.childNodes?.length - 1;
              (
                e.currentTarget.parentNode?.childNodes[
                  indexOfLastElement
                ] as HTMLLIElement
              ).focus();
              break;
            }
            break;

          case 'Tab':
            e.preventDefault();
            break;

          case 'Enter':
            if (option) {
              handleChange(option)();
            }
            break;

          case 'Escape':
            e.preventDefault();
            collapse();
            break;
        }
      },
      [handleChange, collapse],
    );

    const handleButtonKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Escape') {
          e.preventDefault();
          collapse();
        }
      },
      [collapse],
    );

    return (
      <div ref={dropdownRef} className={`dropdown ${className}`}>
        {icon && (
          <div className="dropdown-icon" onClick={triggerIsOpened}>
            {icon}
          </div>
        )}
        <button
          ref={buttonRef}
          id={id}
          aria-label="Toggle dropdown"
          aria-haspopup="true"
          aria-expanded={isOpen}
          type="button"
          role="combobox"
          onClick={triggerIsOpened}
          className="dropdown-trigger"
          aria-controls={`${id}-listbox`}
          onKeyDown={(e): void => handleButtonKeyDown(e)}
        >
          <span>{title || selectedItem?.name || placeholder}</span>
          <i
            className={`dropdown-trigger-icon ${
              isOpen ? 'dropdown-trigger-icon__opened' : ''
            }`}
          ></i>
        </button>
        {isOpen && (
          <div className="dropdown-menu-wrapper">
            <ul
              role="list"
              aria-labelledby={id}
              className="dropdown-menu"
              id={`${id}-listbox`}
            >
              {data?.map((item) => (
                <li
                  key={item.id}
                  onClick={handleChange(item)}
                  className={`dropdown-menu-item ${
                    selectedItem?.id === item.id
                      ? 'dropdown-menu-item__selected'
                      : ''
                  }`}
                  onKeyDown={(e): void => handleOptionKeyDown(e, item)}
                  tabIndex={isOpen ? 0 : -1}
                >
                  <span>{item.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  },
);
