import { UIConfig } from '../models';

export const initThemeStyles = (uiConfig: UIConfig): void => {
  Object.entries(uiConfig.themes).map(([themeName, variables]) => {
    const variableDeclaration = Object.entries(variables).map(
      ([key, value]) => {
        return `--${key}: ${value};`;
      },
    );

    const styleElement = document.createElement('style');
    styleElement.innerHTML = `.theme-${themeName} { ${variableDeclaration.join(
      ' ',
    )} }`;
    document.body.appendChild(styleElement);
  });

  document.body.classList.add(`theme-${uiConfig.defaultTheme}`);
};
