import React, { KeyboardEvent } from 'react';
import './RadioButton.scss';

interface RadioButtonProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

export const RadioButton = React.memo(
  ({ label, checked, onChange }: RadioButtonProps): JSX.Element => {
    const defaultChecked = checked ? checked : false;
    const handleChange = (): void => {
      onChange(!checked);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter') {
        event.stopPropagation();
        event.preventDefault();
        onChange(!checked);
      }
    };

    return (
      <div className="radio-wrapper">
        <label>
          <input
            type="radio"
            checked={defaultChecked}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <span>{label}</span>
        </label>
      </div>
    );
  },
);
