import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import { HomePage, GenericErrorPage, ResultPage } from './pages';
import { configurationService } from './services/configuration.service';
import { Config } from './models';
import { Loading } from './components/Loading/Loading';

enum AppState {
  Loading,
  Loaded,
  Failed,
}

// Wrapper component to handle redirection with query parameters
const RedirectWithParams = (): JSX.Element => {
  const location = useLocation();
  return <Navigate to={`/${location.search}`} />;
};

export const App = React.memo((): JSX.Element => {
  const [state, setState] = useState(AppState.Loading);
  const [config, setConfig] = useState<Config>();

  useEffect(() => {
    const initConfigs = async (): Promise<void> => {
      try {
        await configurationService.loadConfig();
        setConfig(configurationService.getConfig());
        setState(AppState.Loaded);
      } catch (err) {
        setState(AppState.Failed);
      }
    };

    void initConfigs();
  }, []);

  switch (state) {
    case AppState.Loading:
      return <Loading />;
    case AppState.Loaded:
      return (
        <RouterProvider
          router={createBrowserRouter([
            {
              path: '/',
              element: config ? <HomePage config={config} /> : <div></div>,
            },
            {
              path: 'error',
              element: <GenericErrorPage />,
            },
            {
              path: 'result',
              element: config ? <ResultPage config={config} /> : <div></div>,
            },
            {
              path: '*',
              element: <RedirectWithParams />,
            },
          ])}
        />
      );
    case AppState.Failed:
      return <GenericErrorPage />;
  }
});
