import { apiService } from './api.service';
import {
  SubscriptionsData,
  SubscriptionsFormData,
  SubscriptionsPayload,
  SubscriptionsResponse,
} from '../models';

export const NO_GROUP_NAME = 'no-group';

export class ConsentsService {
  async loadConsents(): Promise<{
    subscriptionsData: SubscriptionsData;
    email: string;
  }> {
    const response = await apiService.getPersonSubscriptions();
    return this.convertResponseToSubscriptionData(response);
  }

  async saveConsents(consents: SubscriptionsFormData): Promise<void> {
    const payload: SubscriptionsPayload = {
      encryptedPayload: apiService.payload,
      subscriptions: Object.entries(consents).reduce((acc, [key, value]) => {
        acc.push({
          code: key,
          given: value,
        });
        return acc;
      }, [] as { code: string; given: boolean }[]),
    };
    return await apiService.post('api/v1/PersonSubscriptions', payload);
  }

  private convertResponseToSubscriptionData(response: SubscriptionsResponse): {
    subscriptionsData: SubscriptionsData;
    email: string;
  } {
    const subscriptionsData = response.subscriptions.reduce(
      (acc, subscription) => {
        let groupName = subscription.groupName;

        if (!groupName && !subscription.isVisible) {
          return acc;
        }
        if (!groupName) {
          groupName = NO_GROUP_NAME;
        }

        if (acc[groupName]) {
          acc[groupName].push(subscription);
        } else {
          acc[groupName] = [subscription];
        }
        return acc;
      },
      {} as SubscriptionsData,
    );
    return {
      email: response.identifier,
      subscriptionsData: this.sortSubscriptionsData(subscriptionsData),
    };
  }

  // sort it to have groups with hidden consents in the end
  private sortSubscriptionsData = (
    data: SubscriptionsData,
  ): SubscriptionsData => {
    return Object.fromEntries(
      Object.entries(data).sort(([keyA, valueA], [keyB, valueB]) => {
        const isEmptyA = valueA.filter((c) => c.isVisible).length === 0 ? 1 : 0;
        const isEmptyB = valueB.filter((c) => c.isVisible).length === 0 ? 1 : 0;
        return isEmptyA - isEmptyB;
      }),
    );
  };
}

export const consentsService = new ConsentsService();
