import React from 'react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';

export const Footer = React.memo((): JSX.Element => {
  const { t } = useTranslation();

  return (
    <footer
      className="footer"
      dangerouslySetInnerHTML={{ __html: t('footerText') }}
    ></footer>
  );
});
