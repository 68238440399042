import React, { useCallback, useEffect, useState } from 'react';
import './HomePage.scss';
import { Banner } from '../../components';
import { Config, SubscriptionsData, SubscriptionsFormData } from '../../models';
import { Header, Footer, Intro, Subscriptions } from '../../blocks';
import { consentsService } from '../../services/consents.service';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Loading } from '../../components/Loading/Loading';

export const HomePage = React.memo(
  ({ config }: { config: Config }): JSX.Element => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [subscriptions, setSubscriptions] = useState<SubscriptionsData>();

    const loadData = async (): Promise<void> => {
      try {
        const { email, subscriptionsData } =
          await consentsService.loadConsents();
        setEmail(email);
        setSubscriptions(subscriptionsData);
      } catch {
        navigate('/error');
      }
    };

    const navigateToResult = useCallback(
      (success: boolean): void => {
        const urlParams = new URLSearchParams(window.location.search);

        navigate(
          {
            pathname: '/result',
            search:
              '?' +
              createSearchParams({
                payload: (urlParams.get('payload') as string) || '',
              }),
          },
          {
            state: {
              success,
            },
          },
        );
      },
      [navigate],
    );

    useEffect(() => {
      void loadData();
    }, []);

    const onSave = useCallback(
      async (formData: SubscriptionsFormData) => {
        try {
          await consentsService.saveConsents(formData);
          navigateToResult(true);
        } catch (error) {
          navigateToResult(false);
        }
      },
      [navigateToResult],
    );

    return subscriptions ? (
      <div className="home-page">
        <Header config={config} />
        <main>
          <Banner
            className="banner-top"
            bannerImage={config.uiConfig.images.banner}
          />
          <div className="home-layout">
            <div className="home-content">
              <Intro email={email}></Intro>
              <Subscriptions
                config={config}
                subscriptions={subscriptions}
                onSave={onSave}
              ></Subscriptions>
            </div>
            <div>
              <Banner
                className="banner-right"
                bannerImage={
                  config.uiConfig.images.bannerSquare ||
                  config.uiConfig.images.banner
                }
              />
            </div>
          </div>
        </main>
        {!config.appConfig.hideFooter && <Footer />}
      </div>
    ) : (
      <Loading />
    );
  },
);
