import React from 'react';
import './Header.scss';
import { Config } from '../../models';
import { Logo } from '../../components';
import { LanguageSelector } from '../../components/LanguageSelector/LanguageSelector';

export const Header = React.memo(
  ({ config }: { config: Config }): JSX.Element => {
    return (
      <header className="header">
        <a
          href={config.appConfig.websiteUrl}
          className="header-logo"
          aria-label="Company logo"
        >
          <Logo logoImage={config.uiConfig.images.logo} />
        </a>

        {config.appConfig.languageSelectorEnabled && <LanguageSelector />}
      </header>
    );
  },
);
