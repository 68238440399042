export const LANGUAGES_MAPPING: Record<string, string> = {
  af: 'Afrikaans',
  am: 'አማርኛ',
  ar: 'العربية',
  as: 'অসমীয়া',
  av: 'Авар мацӀ',
  ay: 'Aymar aru',
  az: 'Azərbaycan',
  ba: 'Башҡорт теле',
  be: 'Беларуская',
  bg: 'Български',
  bi: 'Bislama',
  bm: 'Bamanankan',
  bn: 'বাংলা',
  bo: 'བོད་ཡིག',
  br: 'Brezhoneg',
  bs: 'Bosanski',
  ca: 'Català',
  ce: 'Нохчийн',
  ch: 'Chamoru',
  co: 'Corsu',
  cr: 'Nehiyaw',
  cs: 'Čeština',
  cv: 'Чӑвашла',
  cy: 'Cymraeg',
  da: 'Dansk',
  de: 'Deutsch',
  dv: 'ދިވެހި',
  dz: 'རྫོང་ཁ',
  ee: 'Eʋegbe',
  el: 'Ελληνικά',
  en: 'English',
  es: 'Español',
  et: 'Eesti',
  eu: 'Euskara',
  fa: 'فارسی',
  ff: 'Fulfulde',
  fi: 'Suomi',
  fj: 'Vosa Vakaviti',
  fo: 'Føroyskt',
  fr: 'Français',
  fy: 'Frysk',
  ga: 'Gaeilge',
  gd: 'Gàidhlig',
  gl: 'Galego',
  gu: 'ગુજરાતી',
  ha: 'Hausa',
  he: 'עברית',
  hi: 'हिन्दी',
  ho: 'Hiri Motu',
  hr: 'Hrvatski',
  ht: 'Kreyòl ayisyen',
  hu: 'Magyar',
  hy: 'Հայերեն',
  hz: 'Otjiherero',
  ia: 'Interlingua',
  id: 'Bahasa Indonesia',
  ig: 'Asụsụ Igbo',
  ii: 'ꆇꉙ',
  ik: 'Iñupiatun',
  is: 'Íslenska',
  it: 'Italiano',
  iu: 'ᐃᓄᒃᑎᑐᑦ',
  ja: '日本語',
  jv: 'Basa Jawa',
  ka: 'ქართული',
  kg: 'Kikongo',
  ki: 'Gĩkũyũ',
  kj: 'Kuanyama',
  kk: 'Қазақ тілі',
  kl: 'Kalaallisut',
  km: 'ភាសាខ្មែរ',
  kn: 'ಕನ್ನಡ',
  ko: '한국어',
  kr: 'Kanuri',
  ks: 'कश्मीरी',
  ku: 'Kurdî',
  kv: 'Коми кыв',
  kw: 'Kernewek',
  ky: 'Кыргызча',
  la: 'Latina',
  lb: 'Lëtzebuergesch',
  lg: 'Luganda',
  li: 'Limburgs',
  ln: 'Lingála',
  lo: 'ລາວ',
  lt: 'Lietuvių',
  lu: 'Luba-Katanga',
  lv: 'Latviešu',
  mg: 'Malagasy',
  mh: 'Kajin M̧ajeļ',
  mi: 'Te Reo Māori',
  mk: 'Македонски',
  ml: 'മലയാളം',
  mn: 'Монгол',
  mr: 'मराठी',
  ms: 'Bahasa Melayu',
  mt: 'Malti',
  my: 'ဗမာစာ',
  nb: 'Norsk Bokmål',
  ne: 'नेपाली',
  nl: 'Nederlands',
  nn: 'Norsk Nynorsk',
  no: 'Norsk',
  ny: 'Chichewa',
  oc: 'Occitan',
  om: 'Afaan Oromoo',
  or: 'ଓଡ଼ିଆ',
  os: 'Ирон æвзаг',
  pa: 'ਪੰਜਾਬੀ',
  pl: 'Polski',
  ps: 'پښتو',
  pt: 'Português',
  qu: 'Runa Simi',
  rm: 'Rumantsch',
  rn: 'Ikirundi',
  ro: 'Română',
  ru: 'Русский',
  rw: 'Kinyarwanda',
  sa: 'संस्कृतम्',
  sc: 'Sardu',
  sd: 'سنڌي',
  se: 'Davvisámegiella',
  sg: 'Sängö',
  sh: 'Srpskohrvatski',
  si: 'සිංහල',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  sm: 'Gagana Samoa',
  sn: 'ChiShona',
  so: 'Soomaali',
  sq: 'Shqip',
  sr: 'Српски',
  ss: 'SiSwati',
  st: 'Sesotho',
  su: 'Basa Sunda',
  sv: 'Svenska',
  sw: 'Kiswahili',
  ta: 'தமிழ்',
  te: 'తెలుగు',
  tg: 'Тоҷикӣ',
  th: 'ไทย',
  ti: 'ትግርኛ',
  tk: 'Türkmençe',
  tl: 'Tagalog',
  tn: 'Setswana',
  to: 'Lea Faka-Tonga',
  tr: 'Türkçe',
  ts: 'Xitsonga',
  tt: 'Татарча',
  tw: 'Twi',
  ty: 'Reo Tahiti',
  ug: 'ئۇيغۇرچە',
  uk: 'Українська',
  ur: 'اردو',
  uz: 'Oʻzbekcha',
  ve: 'Tshivenḓa',
  vi: 'Tiếng Việt',
  vo: 'Volapük',
  wa: 'Walon',
  wo: 'Wolof',
  xh: 'isiXhosa',
  yi: 'ייִדיש',
  yo: 'Yorùbá',
  za: 'Saɯ cueŋƅ',
  zh: '中文',
  zu: 'isiZulu',
};
