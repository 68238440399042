import React, { KeyboardEvent } from 'react';
import './Checkbox.scss';

interface CheckboxProps {
  checked: boolean;
  indeterminate?: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

export const Checkbox = React.memo(
  ({ label, checked, indeterminate, onChange }: CheckboxProps): JSX.Element => {
    const checkboxRef = React.useRef<HTMLInputElement>(null);
    const handleChange = (): void => {
      onChange(!checked);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter') {
        event.stopPropagation();
        event.preventDefault();
        onChange(!checked);
      }
    };

    React.useEffect(() => {
      if (checkboxRef.current) {
        checkboxRef.current.checked = checked;
        checkboxRef.current.indeterminate = indeterminate ?? false;
      }
    }, [indeterminate, checked]);

    return (
      <div className="checkbox-wrapper">
        <label>
          <input
            ref={checkboxRef}
            type="checkbox"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <span>{label}</span>
        </label>
      </div>
    );
  },
);
