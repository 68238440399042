import { LanguagesConfig } from '../models';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const DEFAULT_LANGUAGE = 'en';
const LANGUAGE_QUERY_PARAM = 'lang';

export class TranslationsService {
  private availableLanguages: string[] = [];
  private currentLanguage = DEFAULT_LANGUAGE;

  getAvailableLanguages(): string[] {
    return this.availableLanguages;
  }

  getCurrentLanguage(): string {
    return this.currentLanguage;
  }

  async changeLanguage(lang: string): Promise<void> {
    if (!this.availableLanguages.includes(lang)) {
      return;
    }

    await i18n.changeLanguage(lang);

    const url = new URL(location.href);
    url.searchParams.set(LANGUAGE_QUERY_PARAM, lang);
    history.pushState(null, '', url);
  }

  async initTranslations(languages: LanguagesConfig): Promise<void> {
    const langParam =
      new URLSearchParams(window.location.search).get(LANGUAGE_QUERY_PARAM) ||
      '';
    this.availableLanguages = Object.keys(languages);
    this.currentLanguage = this.availableLanguages.includes(langParam)
      ? langParam
      : DEFAULT_LANGUAGE;

    await i18n.use(initReactI18next).init({
      resources: {
        ...languages,
      },
      lng: this.currentLanguage,
      fallbackLng: DEFAULT_LANGUAGE,

      interpolation: {
        escapeValue: false,
      },
    });
  }
}

export const translationsService = new TranslationsService();
