import React from 'react';
import './Logo.scss';

export const Logo = React.memo(
  ({ logoImage }: { logoImage: string }): JSX.Element => {
    return (
      <img
        className="logo-image"
        src={logoImage}
        alt="Company logo image"
        aria-label="Company logo image"
      />
    );
  },
);
