import { z } from 'zod';

const colorRegex =
  /^(?<rgb>rgb\((?<red>\d{1,3}),\s*\d{1,3},\s*\d{1,3}\))$|^(?<rgba>rgba\(\d{1,3},\s*\d{1,3},\s*\d{1,3},\s*[01](\.\d{1,2})?\))$|^(?<hex>#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})([0-9a-fA-F]{2})?)|transparent$/;
const colorErrorMessage = 'Color value is invalid';
const cssUnitsRegex =
  /((-?(\d*\.)?\d+)((px)|(em)|(%)|(ex)|(ch)|(rem)|(vw)|(vh)|(vmin)|(vmax)|(cm)|(mm)|(in)|(pt)|(pc)))|auto|fit-content$/;
const cssUnitErrorMessage = 'Unit value is invalid';

export const ThemeScheme = z
  .object({
    'primary-text-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage }),
    'primary-font-family': z.string(),
    'primary-font-size': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'background-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage }),

    'header-padding-top': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'header-padding-bottom': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'header-padding-left': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'header-padding-right': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'header-background-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage }),
    'header-border-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'header-border-width': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'header-align': z.string(),
    'header-icon-fill': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'header-padding-top-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'header-padding-bottom-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'header-padding-left-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'header-padding-right-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'header-align-mobile': z.string().optional(),

    'footer-padding-top': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'footer-padding-bottom': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'footer-padding-left': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'footer-padding-right': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'footer-background-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage }),
    'footer-align': z.string(),
    'footer-text-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage }),

    'layout-padding-top': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'layout-padding-bottom': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'layout-padding-right': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'layout-padding-left': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'subscription-options-max-column-number': z.string().optional(),
    'subscription-group-options-max-column-number': z.string().optional(),

    'content-background': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage }),
    'content-border-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'max-content-width': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-margin-right': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-border-width': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'layout-padding-top-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'layout-padding-bottom-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'layout-padding-right-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'layout-padding-left-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'logo-width': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'logo-height': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'banner-width': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'banner-height': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'banner-width-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'banner-height-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'intro-header-font-family': z.string().optional(),
    'intro-header-font-size': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'intro-header-font-size-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'intro-header-font-weight': z.string().optional(),
    'intro-header-align': z.string(),
    'intro-text-align': z.string(),
    'intro-header-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'intro-header-align-mobile': z.string(),
    'intro-text-align-mobile': z.string(),

    'content-padding-top': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-padding-bottom': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-padding-left': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-padding-right': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'content-padding-top-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-padding-bottom-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-padding-right-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-padding-left-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'content-item-padding-top': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-item-padding-bottom': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-item-padding-left': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-item-padding-right': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'content-item-padding-top-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-item-padding-bottom-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-item-padding-left-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'content-item-padding-right-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'update-all-gap': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'update-all-align': z.string(),

    'subscription-options-gap': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'subscription-options-align': z.string(),
    'subscription-options-list-padding': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'subscription-options-title-font-size': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'subscription-options-title-font-weight': z.string().optional(),
    'subscription-options-title-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),

    'button-text-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'button-background-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage }),
    'button-font-size': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'button-font-family': z.string().optional(),
    'button-font-weight': z.string().optional(),
    'button-text-transform': z.string().optional(),
    'button-width': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'button-padding': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'button-border-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'button-border-width': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'button-loader-fill': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage }),

    'submit-button-font-size': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'submit-button-text-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'submit-button-font-weight': z.string().optional(),
    'submit-button-font-wight': z.string().optional(),
    'submit-button-text-transform': z.string().optional(),
    'submit-button-letter-spacing': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'submit-button-background-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'submit-button-color-hover': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'submit-button-background-color-hover': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'submit-button-width': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'submit-button-padding': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'submit-button-align': z.string().optional(),
    'submit-button-border-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'submit-button-border-width': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),

    'checkbox-gap': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'checkbox-gap-mobile': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'checkbox-size': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'checkbox-right-margin': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'checkbox-background-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage }),
    'checkbox-border-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'checkbox-check-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage }),

    'radio-size': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'radio-right-margin': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'radio-background-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'radio-check-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),

    'result-content-margin': z.string(),
    'result-content-align': z.string(),
    'result-content-padding': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'success-text-align': z.string(),
    'success-margin': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'success-padding': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'success-header-padding': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'failure-text-align': z.string(),
    'failure-margin': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'failure-padding': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),
    'failure-header-padding': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage }),

    'dropdown-background': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'dropdown-trigger-text-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'dropdown-trigger-background': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'dropdown-trigger-width': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'dropdown-trigger-icon-margin-left': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'dropdown-background-selected': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'dropdown-background-hover': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'dropdown-border-color': z
      .string()
      .regex(colorRegex, { message: colorErrorMessage })
      .optional(),
    'dropdown-item-height': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'dropdown-item-padding-top': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'dropdown-item-padding-right': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'dropdown-item-padding-bottom': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'dropdown-item-padding-left': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'dropdown-trigger-padding-top': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'dropdown-trigger-padding-right': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'dropdown-trigger-padding-bottom': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
    'dropdown-trigger-padding-left': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),

    'language-icon-margin-right': z
      .string()
      .regex(cssUnitsRegex, { message: cssUnitErrorMessage })
      .optional(),
  })
  .strict();
