import { z } from 'zod';

export const AppConfigSchema = z
  .object({
    hasSubscribeAllButton: z.boolean(),
    hasUnsubscribeAllButton: z.boolean(),
    hasSubscriptionOptionsTitle: z.boolean(),
    hasSubscribeAllText: z.boolean().optional(),
    hasUnsubscribeAllText: z.boolean().optional(),
    websiteUrl: z.string().url(),
    languageSelectorEnabled: z.boolean(),
    subscribeAndUnsubscribeAllButtonType: z
      .string()
      .regex(/^button|radio$/, 'Invalid value. Can be "button" or "radio"'),
    hideFooter: z.boolean().optional(),
  })
  .strict();
