import React from 'react';
import './Banner.scss';

export const Banner = React.memo(
  ({
    bannerImage,
    className,
  }: {
    bannerImage: string;
    className: string;
  }): JSX.Element => {
    return (
      <img
        className={`${className} banner-image`}
        alt="Banner"
        src={bannerImage}
        aria-label="Banner"
      />
    );
  },
);
