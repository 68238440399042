import React, { useState, FormEvent, useMemo } from 'react';
import './Subscriptions.scss';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { SubscriptionsData, Config, SubscriptionsFormData } from '../../models';
import { Button } from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { UpdateAll } from '../../components/UpdateAll/UpdateAll';
import { NO_GROUP_NAME } from '../../services/consents.service';

const mapSubscriptionsToFormData = (
  subscriptionGroups: SubscriptionsData,
): SubscriptionsFormData =>
  Object.values(subscriptionGroups).reduce((acc, subscriptionsData) => {
    subscriptionsData.forEach(({ code, given }) => {
      acc[code] = given;
    });
    return acc;
  }, {} as SubscriptionsFormData);

interface SubscriptionsProps {
  subscriptions: SubscriptionsData;
  config: Config;
  onSave: (formData: SubscriptionsFormData) => Promise<void>;
}

export const Subscriptions = React.memo(
  ({ config, subscriptions, onSave }: SubscriptionsProps): JSX.Element => {
    const { t } = useTranslation();
    const initialFormData = mapSubscriptionsToFormData(subscriptions);

    const [formData, setFormData] = useState(initialFormData);
    const [showLoader, setShowLoader] = useState(false);

    const isSubscribedToAll = useMemo(
      () => Object.values(formData).every((value) => value),
      [formData],
    );
    const isUnSubscribedFromAll = useMemo(
      () => Object.values(formData).every((value) => !value),
      [formData],
    );
    const isSubmitDisabled = useMemo(
      () =>
        showLoader ||
        Object.values(subscriptions).every((group) =>
          group.every(({ code }) => formData[code] === initialFormData[code]),
        ),
      [showLoader, formData],
    );

    const handleCheckboxChange = (key: string) => (value: boolean) =>
      setFormData((prevState) => ({ ...prevState, [key]: value }));

    const updateAllCheckboxes = (value: boolean): void => {
      setFormData((prevState) =>
        Object.keys(prevState).reduce(
          (acc, key) => ({ ...acc, [key]: value }),
          {} as SubscriptionsFormData,
        ),
      );
    };

    const handleGroupCheckboxChange =
      (groupName: string) =>
      (value: boolean): void => {
        const updatedState = subscriptions[groupName].reduce(
          (acc, { code }) => ({ ...acc, [code]: value }),
          {},
        );
        setFormData((prevState) => ({ ...prevState, ...updatedState }));
      };

    const onSubmit = (event: FormEvent): void => {
      event.preventDefault();
      setShowLoader(true);
      void onSave(formData);
    };

    const subscribeToAll = (): void => updateAllCheckboxes(true);
    const unSubscribeFormAll = (): void => updateAllCheckboxes(false);

    return (
      <form onSubmit={onSubmit}>
        <div className="home-content-item">
          {config.appConfig.hasSubscribeAllButton && (
            <UpdateAll
              config={config}
              label={t('subscribeToAll')}
              onChange={subscribeToAll}
              checked={isSubscribedToAll}
              hasTitle={config.appConfig.hasSubscribeAllText}
              title={t('subscribeToAllTitle')}
            />
          )}
        </div>

        <div className="subscription-options home-content-item">
          {config.appConfig.hasSubscriptionOptionsTitle && (
            <p className="subscription-options-title">
              {t('subscriptionOptionsTitle')}
            </p>
          )}
          <ul className="subscription-options-list">
            {subscriptions[NO_GROUP_NAME]?.map(
              ({ code, displayName, isVisible }) =>
                isVisible ? (
                  <li key={code}>
                    <Checkbox
                      checked={formData[code]}
                      onChange={handleCheckboxChange(code)}
                      label={displayName}
                    />
                  </li>
                ) : null,
            )}
            {Object.entries(subscriptions).map(
              ([groupName, groupSubscriptions]) =>
                groupName !== NO_GROUP_NAME ? (
                  <li key={groupName}>
                    <Checkbox
                      onChange={handleGroupCheckboxChange(groupName)}
                      checked={groupSubscriptions.every(
                        ({ code }) => formData[code],
                      )}
                      indeterminate={
                        groupSubscriptions.some(({ code }) => formData[code]) &&
                        !groupSubscriptions.every(({ code }) => formData[code])
                      }
                      label={groupName}
                    />
                    <ul className="subscription-options-list group-options">
                      {groupSubscriptions.map(
                        ({ code, displayName, isVisible }) =>
                          isVisible ? (
                            <li key={code}>
                              <Checkbox
                                checked={formData[code]}
                                onChange={handleCheckboxChange(code)}
                                label={displayName}
                              />
                            </li>
                          ) : null,
                      )}
                    </ul>
                  </li>
                ) : null,
            )}
          </ul>
        </div>

        <div className="home-content-item">
          {config.appConfig.hasUnsubscribeAllButton && (
            <UpdateAll
              config={config}
              label={t('unsubscribeFromAll')}
              onChange={unSubscribeFormAll}
              checked={isUnSubscribedFromAll}
              hasTitle={config.appConfig.hasUnsubscribeAllText}
              title={t('unsubscribeFromAllTitle')}
            />
          )}
        </div>

        <div className="submit-button home-content-item">
          <Button
            type="submit"
            loading={showLoader}
            disabled={isSubmitDisabled}
          >
            {t('updateButton')}
          </Button>
        </div>
      </form>
    );
  },
);
