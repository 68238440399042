import React, { PropsWithChildren } from 'react';
import './Button.scss';
import { Loader } from '../Loader/Loader';

interface ButtonProps extends PropsWithChildren {
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
}

export const Button = React.memo(
  ({
    children,
    onClick,
    type = 'button',
    disabled,
    loading,
  }: ButtonProps): JSX.Element => {
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className="button"
      >
        {loading && <Loader />} <div className="button-content">{children}</div>
      </button>
    );
  },
);
