import React from 'react';
import './Loading.scss';

export const Loading = React.memo((): JSX.Element => {
  return (
    <div className="wrapper">
      <span className="loader"></span>
    </div>
  );
});
