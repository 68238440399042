import { z } from 'zod';
import { UiConfigSchema } from './ui-config-scheme';
import { AppConfigSchema } from './app-config-scheme';
import { LanguageScheme } from './language-config-scheme';

export const ConfigScheme = z.object({
  uiConfig: UiConfigSchema,
  appConfig: AppConfigSchema,
  languages: LanguageScheme,
});
