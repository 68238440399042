import React from 'react';
import './Intro.scss';
import { useTranslation } from 'react-i18next';

export const Intro = React.memo(({ email }: { email: string }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="intro home-content-item">
      <h1 className="intro-header">{t('header', { email })}</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: t('content', { email }),
        }}
        className="intro-content"
      ></p>
    </div>
  );
});
