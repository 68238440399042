import { z } from 'zod';
import { ThemeScheme } from './theme-scheme';
const FontSourceScheme = z.object({
  data: z.string(),
  format: z.string(),
});

export const FontVariationScheme = z.record(
  z.string(),
  z.record(z.string(), FontSourceScheme),
);

export const UiConfigSchema = z
  .object({
    images: z
      .object({
        successImage: z.string(),
        failureImage: z.string(),
        banner: z.string(),
        bannerSquare: z.string().optional(),
        logo: z.string(),
        favicon: z.string(),
      })
      .strict(),
    defaultTheme: z.string(),
    fontAssets: z.record(z.string(), FontVariationScheme),
    themes: z.record(z.string(), ThemeScheme),
  })
  .strict();
